import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/showMsg/:mesId',
    name: 'showMsg',
    component: () => import('../view/showMsg/index.vue'),
    props: true,
    meta:{
      title:"消息阅读"
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 在路由导航之前，设置页面标题
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '消息阅读'
  next()
})

export default router
